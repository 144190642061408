import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import AboutImg from "../assets/register.jpg"
import EventType from "../components/EventType"
import  Footer from "../components/Footer";
import { Typography } from "@mui/material";
import "../styles.css"

function RegistrationRoute() {
    return (
        <>
        <Navbar></Navbar>
        <Hero
            className="hero-mid"
            heroImg={AboutImg}
            title="Registration"
            btnClass="hide"
        ></Hero>
        <div className="flexbox-container">
            <EventType
                title="Scholastic - 11/30"
                entryFee="Entry Fee: "
                content=
                "$40 by 11/03, $45 by 11/24, $50 on or after 11/25"
                registerLink="https://caissachess.net/online-registration/index/5145"
                advEntriesLink="https://www.caissachess.net/live/5145"
            >
            </EventType>
            <EventType
                title="Open"
                entryFee="Entry Fee: "
                content=
                "$115 by 9/30, $135 by 10/31, $155 by 11/24, $175 on/after 11/25"
                registerLink="https://caissachess.net/online-registration/index/5009"
                advEntriesLink="https://www.caissachess.net/live/5009"
            >
            </EventType>
            <EventType
                title="Blitz - 11/30"
                entryFee="Entry Fee:"
                content=
                "$30 by 11/24, $35 on or after 11/25"
                registerLink="https://caissachess.net/online-registration/index/5146"
                advEntriesLink="https://www.caissachess.net/live/5146"
            >
            </EventType>
        </div>
        <div className="flexbox-container">
            <EventType
                title="Bughouse - 11/29"
                entryFee="Entry Fee: "
                content=
                "Bughouse Only: $15 by 11/03, $20 by 11/24, $25 on or after 11/25."
                registerLink="https://caissachess.net/online-registration/index/5143"
                advEntriesLink="https://www.caissachess.net/live/5143"
            >
            </EventType>
            <EventType
                title="Action - 11/29"
                entryFee="Entry Fee: "
                content=
                "Action Only: $40 by 11/03, $45 by 11/24, $50 on or after 11/25"
                registerLink="https://caissachess.net/online-registration/index/5144"
                advEntriesLink="https://www.caissachess.net/live/5144"
            >
            </EventType>
        </div>
        <div>
        <Typography color="black" variant="h5" sx={{fontFamily: 'Poppins'}}>
            Bughouse + Action: $50 by 11/03, $60 by 11/24, $70 on or after 11/25
        </Typography>
        <br />
        </div>
        <Footer></Footer>
        </>
    )
}

export default RegistrationRoute;